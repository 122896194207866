import Head from 'next/head';
import React from 'react';

import CookieConsent from './CookieConsent';
import Footer from './Footer';
import Nav from './Nav';

type Props = {
  children: any;
};

type State = {
  dark: boolean;
};

class Layout extends React.Component<Props, State> {
  constructor({ props }) {
    super(props);

    this.state = {
      dark: false,
    };
  }

  componentDidMount() {
    const isDark = localStorage.getItem("darkmode") === "true";
    this.setState({
      dark: isDark,
    });
  }

  render() {
    return (
      <>
        <Head>
          <title>Bringmos • Südtiroler Bestellplattform</title>
          <link rel="icon" href="/icons/apple-touch-icon.png" />
        </Head>
        <div id="themediv" className={this.state.dark ? "dark" : ""}>
          <div className="relative flex flex-col min-h-screen w-full bg-lightgrey dark:bg-gray-900 dark:text-white">
            <Nav></Nav>
            <main className="flex-grow w-full">{this.props.children}</main>
            <Footer></Footer>
            <CookieConsent></CookieConsent>
          </div>
        </div>
      </>
    );
  }
}

export default Layout;
