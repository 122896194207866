import withTranslation from 'next-translate/withTranslation';
import Link from 'next/link';
import React from 'react';

type Props = {
  i18n: any;
};

type State = {
  show: boolean;
};

class CookieConsent extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    if (typeof window !== "undefined") {
      this.state = {
        show: false,
      };
    }
  }

  componentDidMount() {
    const showConsent: boolean =
      localStorage.getItem("cookieconsent_dismissed") != "true";
    this.setState({
      show: showConsent,
    });
  }

  dismiss() {
    localStorage.setItem("cookieconsent_dismissed", "true");
    this.setState({
      show: false,
    });
  }

  render() {
    const { t } = this.props.i18n;

    return (
      <div
        className={`fixed ${
          this.state?.show ? "bottom-0 " : "bottom-cookieconsentoffset "
        } z-30 m-12 mx-8 p-4 py-2 flex items-center text-sm bg-gray-200 dark:bg-gray-700 rounded-full shadow transition-all`}
      >
        <div className="flex-1 text-black dark:text-white mr-2 flex flex-row items-center">
          <span className="mr-2 text-xl">🍪</span>
          <div>
            <span className="mr-2">{t("common:cookiewarn")}</span>
            <Link href="/legal">
              <a className="text-bringmos-500">{t("common:cookie_readmore")}</a>
            </Link>
          </div>
        </div>
        <button
          onClick={() => this.dismiss()}
          className="p-2 outline-none focus:outline-none "
        >
          <i className="text-xl las la-times-circle"></i>
        </button>
      </div>
    );
  }
}

export default withTranslation(CookieConsent);
