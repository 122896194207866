import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import withTranslation from "next-translate/withTranslation";
import Image from "next/image";
import { Router, withRouter } from "next/router";
import React from "react";
import { Fragment } from "react";

/* This example requires Tailwind CSS v2.0+ */
type Props = {
  i18n: any;
  router: Router;
};

type State = {
  selected: Lang;
};

interface Lang {
  id: number;
  name: string;
  img: string;
  code: string;
}

class Language extends React.Component<Props, State> {
  LANGS: Lang[] = [
    {
      id: 1,
      name: "Deutsch",
      code: "de",
      img: "/flags/de.png",
    },
    {
      id: 1,
      name: "Italiano",
      code: "it",
      img: "/flags/it.png",
    },
    {
      id: 3,
      name: "English",
      code: "en",
      img: "/flags/us.png",
    },
  ];

  constructor(props) {
    super(props);

    const findLang = this.LANGS.find((l) => l.code == this.props.router.locale);
    if (findLang) {
      this.state = {
        selected: findLang,
      };
    }
  }

  setSelected = (lang: Lang) => {
    // TODO reload on same route, not on homepage
    // this.props.router.push("", "", { locale: lang.code,  });
    const { pathname, query, asPath } = this.props.router;
    this.props.router.push({ pathname, query }, asPath, { locale: lang.code });

    this.setState({
      selected: lang,
    });
  };

  classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  render() {
    const { t } = this.props.i18n;

    return (
      <Listbox value={this.state.selected} onChange={this.setSelected}>
        {({ open }) => (
          <>
            <div className={`relative my-4 z-10 w-40`}>
              <Listbox.Button
                className={`bg-black/10 dark:bg-white/10 h-10 relative w-full rounded-md shadow-sm pl-3 pr-10 py-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-black dark:focus:ring-white sm:text-sm`}
              >
                <span className="flex items-center">
                  <Image
                    height={24}
                    width={24}
                    src={this.state.selected.img}
                    alt=""
                    className="flex-shrink-0 rounded-full"
                  />
                  <span className="ml-3 block truncate">
                    {this.state.selected.name}
                  </span>
                </span>
                <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon className="h-5 w-5" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="absolute -top-12 mt-1 w-full bg-beige dark:bg-gray-800 shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  {this.LANGS.map((lang, i) => (
                    <Listbox.Option
                      key={i}
                      className={({ active }) =>
                        this.classNames(
                          active
                            ? "text-white bg-bringmos-500"
                            : "text-gray-900 dark:text-white",
                          "cursor-default select-none relative py-2 pl-3 pr-9"
                        )
                      }
                      value={lang}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            <Image
                              height={24}
                              width={24}
                              src={lang.img}
                              alt=""
                              className="flex-shrink-0 h-6 w-6 rounded-full"
                            />
                            <span
                              className={this.classNames(
                                selected ? "font-semibold" : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {lang.name}
                            </span>
                          </div>

                          {selected ? (
                            <span
                              className={this.classNames(
                                active ? "text-white" : "text-bringmos-500",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    );
  }
}

export default withTranslation(withRouter(Language));
