import { LoginIcon } from "@heroicons/react/outline";
import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";

import { UserContext } from "../lib/context";
import ImageWithFallback from "./ImageWithFallback";

export default function Nav() {
  const { user, clientData } = useContext(UserContext);

  const router = useRouter();
  const { pathname, query } = router;
  const { link } = query;
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { t } = useTranslation("common");
  return (
    <nav
      className={`px-6 z-20 transition-all duration-300 fixed top-0 inset-x-0 flex items-center h-14 bg-white bg-opacity-50 dark:bg-navdark ${
        (pathname == "/" || link !== undefined) && scrollPosition < 376
          ? "bg-transparent dark:bg-transparent"
          : "shadow-navlight dark:shadow-navdark backdrop-blur"
      }`}
    >
      <Link href="/">
        <a
          className={`transition-all duration-300 ease-in-out cursor-pointer font-bringmos font-bold text-xl py-4 ${
            (pathname == "/" || link !== undefined) && scrollPosition < 376
              ? "text-white "
              : "text-black dark:text-white"
          }`}
        >
          {router.locale === "de"
            ? "BRINGMOS"
            : router.locale === "it"
            ? "PORTAMELO"
            : "BRINGMOS"}
        </a>
      </Link>
      <span className="flex-grow"></span>

      {user && user.photoURL ? (
        <Link href="/profile">
          <a className="transition-all cursor-pointer h-10 w-10 ring ring-gray-100 dark:ring dark:ring-gray-700 ring-opacity-70 hover:ring-opacity-100 hover:ring-gray-200 dark:hover:ring-gray-500 rounded-full flex items-center justify-center bg-gray-100 dark:bg-gray-800">
            <ImageWithFallback
              src={user.photoURL}
              layout="fill"
              fallbackSrc="/svg/user.svg"
              className="rounded-full shadow-sm"
            />
          </a>
        </Link>
      ) : user?.isAnonymous ? (
        <Link href="/profile">
          <a className="transition-all cursor-pointer h-10 w-10 ring ring-gray-100 dark:ring dark:ring-gray-700 ring-opacity-70 hover:ring-opacity-100 hover:ring-gray-200 dark:hover:ring-gray-500 rounded-full flex items-center justify-center bg-gray-100 dark:bg-gray-800">
            <ImageWithFallback
              src="/svg/user.svg"
              layout="fill"
              fallbackSrc="/svg/user.svg"
              className="rounded-full shadow-sm"
            />
          </a>
        </Link>
      ) : (
        <Link href="/profile">
          <a className="transition-all cursor-pointer h-10 w-10 ring ring-gray-100 dark:ring dark:ring-gray-700 ring-opacity-70 hover:ring-opacity-100 hover:ring-gray-200 dark:hover:ring-gray-500 rounded-full flex items-center justify-center bg-gray-100 dark:bg-gray-800">
            <LoginIcon className="h-6 w-6"></LoginIcon>
          </a>
        </Link>
      )}
    </nav>
  );
}
