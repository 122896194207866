import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { useRouter } from "next/router";

import Language from "./Language";
import Theme from "./Theme";
import Image from "next/image";

const Footer = () => {
  const { t } = useTranslation("footer");
  const router = useRouter();

  return (
    // bg-beige
    <footer className="bg-gray-50 dark:bg-black border-t border-black/10 dark:border-white/20 dark:text-white text-sm pt-12 w-full relative overflow-hidden">
      {/* <img
        loading="lazy"
        className="absolute dark:hidden object-cover object-right inset-0 h-full w-full"
        src="/images/thirstquencertilted.png"
        alt="footer background"
      /> */}
      {/* <div className="absolute inset-0 bg-black bg-opacity-30 dark:bg-opacity-80"></div> */}
      <div className="mx-auto flex-col px-6 pb-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 md:gap-12 z-10 max-w-6xl mx-auto">
          <div>
            <div className="flex flex-col items-center justify-center md:items-start z-10 mt-14">
              <div className="mb-2">
                <a
                  className="text-4xl hover:font-semibold"
                  href="https://facebook.com/bringmos/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="lab la-facebook"></i>
                </a>
                <a
                  className="text-4xl hover:font-semibold"
                  href="https://www.instagram.com/bringmos/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="lab la-instagram"></i>
                </a>
              </div>
              <span className=" md:mr-6">
                © 2019 - {new Date().getFullYear()} All Rights Reserved.
              </span>
            </div>
            <Theme></Theme>
            <Language></Language>
          </div>
          <div className="font-normal ">
            <h5 className="text-xl mb-6 font-semibold">{t("product.title")}</h5>
            <Link href="#restaurants">
              <a className="my-2 block">{t("product.restaurants")}</a>
            </Link>
            <Link href="https://bringmos.io" target="_blank">
              <a className="my-2 block">{t("product.managementplatform")}</a>
            </Link>
            <Link href="https://bringmos.io/features#terminal">
              <a className="my-2 block">{t("product.terminal")}</a>
            </Link>
            <Link href="https://docs.bringmos.com" target="_blank">
              <a className="my-2 block">{t("product.docs")}</a>
            </Link>

            <div className="flex items-center flex-wrap mt-4">
              <a
                target="_blank"
                rel="noreferrer"
                className="h-12 mr-2 mb-2"
                href="https://play.google.com/store/apps/details?id=com.bringmos.bringmosclient"
              >
                <img
                  height="48px"
                  className="h-full object-contain"
                  src="/images/playstore.png"
                  loading="lazy"
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                className="h-12 mr-2 mb-2"
                href="https://apps.apple.com/us/app/bringmos/id1497420702?l=de&ls=1"
              >
                <img
                  height="48px"
                  className="h-full object-contain"
                  src="/images/appstore.svg"
                  loading="lazy"
                />
              </a>
            </div>
          </div>

          <div className="">
            <h5 className="text-xl mb-6 font-medium">{t("solutions.title")}</h5>
            <Link href="https://bringmos.io/features#menuonly">
              <a className="my-2 block">{t("solutions.menuonly")}</a>
            </Link>
            <Link href="https://bringmos.io/features#manage">
              <a className="my-2 block">{t("solutions.manage")}</a>
            </Link>
            <Link href="https://bringmos.io/features#more">
              <a className="my-2 block">{t("solutions.orderonline")}</a>
            </Link>
            <Link href="https://bringmos.io/features">
              <a className="my-2 block">{t("solutions.paymentmethods")}</a>
            </Link>
          </div>

          <div className="">
            <h5 className="text-xl mb-6 font-medium">{t("links.title")}</h5>

            <Link href="https://bringmos.io/register">
              <a className="my-2 block">{t("links.register")}</a>
            </Link>
            <Link href="/legal">
              <a className="my-2 block">{t("links.legal")}</a>
            </Link>
            <Link href="/partner-agb">
              <a className="my-2 block">{t("links.legalpartner")}</a>
            </Link>
            <Link href="/contact">
              <a className="my-2 block">{t("links.contact")}</a>
            </Link>
            <Link href="/impressum">
              <a className="my-2 block">{t("links.impressum")}</a>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-center pb-12">
        <div className="block dark:hidden">
          <Image
            height={40}
            width={100}
            src="/images/made_in_southtyrol_light.svg"
            alt="made in southtyrol"
          />
        </div>

        <div className="hidden dark:block">
          <Image
            height={40}
            width={100}
            src="/images/made_in_southtyrol_dark.svg"
            alt="made in southtyrol"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
