import { getAnalytics, isSupported } from "firebase/analytics";
import { getApp, getApps, initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
import {
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  getFirestore,
  QueryDocumentSnapshot,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import {
  getRemoteConfig,
  RemoteConfig,
  isSupported as remoteConfigIsSupported,
} from "firebase/remote-config";

import {
  Extra,
  Ingredient,
  MenuItem,
  MenuItemOrder,
  Order,
  Provider,
  User,
} from "../types/src";

export const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

if (!getApps().length) {
  initializeApp(firebaseConfig);
}

const app = getApp();
const auth = getAuth();
const firestore = getFirestore();
const now = Timestamp.now();
const storage = getStorage();
const functions = getFunctions(app, "europe-west1");

const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.setCustomParameters({
  prompt: "select_account",
});
const facebookAuthProvider = new FacebookAuthProvider();
const appleAuthProvider = new OAuthProvider("apple.com");
appleAuthProvider.addScope("email");
appleAuthProvider.addScope("name");

console.log(app.name ? "Firebase Mode Activated!" : "Firebase not working :(");

let analytics;
isSupported().then((supported) => {
  if (supported) {
    analytics = getAnalytics();
  }
});

let remoteConfig: RemoteConfig;
remoteConfigIsSupported().then((supported) => {
  if (supported) {
    remoteConfig = getRemoteConfig();
    remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
  }
});

export {
  auth,
  firestore,
  analytics,
  now,
  storage,
  functions,
  remoteConfig,
  googleAuthProvider,
  facebookAuthProvider,
  appleAuthProvider,
};

export function saveClient(userId: string, data: Partial<User>): Promise<void> {
  const ref = doc(firestore, "Clients", userId);
  return setDoc(ref, data, { merge: true });
}

export function sendOrder(
  order: Order
): Promise<DocumentReference<DocumentData>> {
  const ref = collection(firestore, "Orders");
  return addDoc(ref, order);
}

/**`
 * Converts a firestore document to JSON
 * @param  {DocumentSnapshot} doc
 */
export function providerToJSON(
  doc: QueryDocumentSnapshot<DocumentData> | DocumentSnapshot<DocumentData>
) {
  const data = doc.data() as Provider;
  return {
    ...data,
    // Gotcha! firestore timestamp NOT serializable to JSON. Must convert to milliseconds
    uid: doc.id,
    creation_date: data.creation_date
      ? (data.creation_date as Timestamp).toMillis()
      : null,
  };
}

/**`
 * Converts a firestore document to JSON
 * @param  {DocumentSnapshot} doc
 */
export function orderToJSON(doc: QueryDocumentSnapshot<DocumentData>): any {
  const data = doc.data();
  const mappedMI = data.menuItems?.length
    ? data.menuItems.map((item: MenuItem) => {
        return {
          // date: item.date ? item.date.toMillis() : null,
          ...item,
        };
      })
    : [];

  return {
    ...data,
    id: doc.id,
    menuItems: mappedMI,
    date: data.date ? data.date.toJSON() : null,
    order_target_date: data.order_target_date
      ? data.order_target_date.toJSON()
      : null,
    creation_date: data.order_sent_date ? data.order_sent_date.toJSON() : null,
    last_modified: data.last_modified ? data.last_modified.toJSON() : null,
  };
}

export function menuItemToJSON(doc: QueryDocumentSnapshot<DocumentData>) {
  const data = doc.data() as MenuItem;
  return {
    ...data,
    uid: doc.id,
    date: data.date ? (data.date as Timestamp).toJSON() : null,
    changeDate: data.changeDate
      ? (data.changeDate as Timestamp).toJSON()
      : null,

    unavailableIngredients:
      data.unavailableIngredients && data.unavailableIngredients.length
        ? (data as any).unavailableIngredients.map(ingredientToJSON)
        : [],
  };
}

export function menuItemOrderToJSON(doc: QueryDocumentSnapshot<DocumentData>) {
  const data = doc.data() as MenuItemOrder;

  return {
    ...data,
    uid: doc.id,
    date: data.date ? (data.date as Timestamp).toJSON() : null,
  };
}

export function extraToJSON(doc: QueryDocumentSnapshot<DocumentData>) {
  const data = doc.data() as Extra;
  return {
    ...data,
    uid: doc.id,
    date: data.date ? data.date.toJSON() : null,
  };
}

export function ingredientToJSON(doc: QueryDocumentSnapshot<DocumentData>) {
  const data = doc.data() as Ingredient;
  return {
    ...data,
    uid: doc.id,
  };
}
