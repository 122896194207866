import { useState } from "react";

export default function ImageWithFallback(props) {
  const { src, fallbackSrc, ...rest } = props;
  const [imgSrc, setImgSrc] = useState(src);

  return (
    <img
      {...rest}
      src={imgSrc}
      alt="user avatar"
      onError={() => {
        setImgSrc(fallbackSrc);
      }}
    />
  );
}
