module.exports = {
  locales: ["en", "de", "it"],
  defaultLocale: "de",
  pages: {
    "*": ["common", "footer", "order"],
    "/": ["common", "home"],
    "/profile": ["common", "provider", "profile"],
    "/[...slug]": ["common", "provider", "profile"],
    "/order/[orderId]": ["common", "profile", "provider", "status"],
    "/order/last": ["common", "profile", "provider", "status"],
  },
};
