import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import useTranslation from "next-translate/useTranslation";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import classNames from "classnames";

interface ThemeSetting {
  id: number;
  name: string;
  dark: boolean;
}

const THEMES: ThemeSetting[] = [
  {
    id: 1,
    name: "common:light",
    dark: false,
  },
  {
    id: 2,
    name: "common:dark",
    dark: true,
  },
];

function Theme() {
  const [selected, setSelected] = useState(THEMES[0]);

  useEffect(() => {
    const isDark = localStorage.getItem("darkmode") === "true";
    setSelected(isDark ? THEMES[1] : THEMES[0]);
  }, []);

  function setSelectedTheme(theme: ThemeSetting) {
    console.log(theme);
    localStorage.setItem("darkmode", theme.dark.toString());
    setSelected(theme);

    const div = document.getElementById("themediv");
    if (div && div.classList) {
      if (theme.dark && !div.classList.contains("dark")) {
        div.classList.add("dark");
      } else {
        div.classList.remove("dark");
      }
    }
  }

  const { t } = useTranslation();
  return (
    <Listbox value={selected} onChange={setSelectedTheme}>
      {({ open }) => (
        <>
          <div className={`relative my-4 z-10 w-40`}>
            <Listbox.Button
              className={`h-10 bg-black/10 dark:bg-white/10 relative w-full rounded-md shadow-sm pl-3 pr-10 py-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-black dark:focus:ring-white sm:text-sm`}
            >
              <span className="flex items-center">
                {selected.dark ? (
                  <i className="flex-shrink-0 text-xl rounded-full las la-moon"></i>
                ) : (
                  <i className="flex-shrink-0 text-xl rounded-full las la-sun"></i>
                )}
                <span className="ml-3 block truncate">{t(selected.name)}</span>
              </span>
              <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute -top-12 mt-1 w-full bg-beige dark:bg-gray-800 shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                {THEMES.map((theme) => (
                  <Listbox.Option
                    key={theme.id}
                    className={({ active }) =>
                      classNames(
                        active
                          ? "text-white bg-bringmos-500"
                          : "text-gray-900 dark:text-white",
                        "cursor-default select-none relative py-2 pl-3 pr-9 text-sm"
                      )
                    }
                    value={theme}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          {theme.dark ? (
                            <i className="flex-shrink-0 text-xl rounded-full las la-moon"></i>
                          ) : (
                            <i className="flex-shrink-0 text-xl rounded-full las la-sun"></i>
                          )}
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {t(theme.name)}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-bringmos-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export default Theme;
