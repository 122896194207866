import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { auth, firestore } from "./firebase";

// Custom hook to read  auth record and user profile doc
export function useUserData() {
  const [user] = useAuthState(auth);
  const [clientData, setClientData] = useState(null);

  useEffect(() => {
    let unsubscribe;

    if (user) {
      const ref = doc(collection(firestore, "Clients"), user.uid);
      unsubscribe = onSnapshot(ref, (doc) => {
        setClientData(doc.data());
      });
    } else {
      setClientData(null);
    }

    return unsubscribe;
  }, [user]);

  return { user, clientData };
}
